import { DataSourceSettings } from '@grafana/data';

import { PDCDataSourceJSONData } from '../types';
import { AccessControlAction, ContextServType } from '../types/ContextServType';

const getContextSrv: () => ContextServType | null | undefined = () => require('grafana/app/core/core').contextSrv;

export const isAdmin = (): boolean => {
  return getContextSrv()?.hasRole?.('Admin') === true;
};

export const canEditDataSource = (dataSource: DataSourceSettings<PDCDataSourceJSONData> | undefined): boolean => {
  return (
    !!dataSource &&
    !!(
      getContextSrv()?.hasRole?.('Admin') ||
      getContextSrv()?.hasPermissionInMetadata(AccessControlAction.DataSourcesCreate, dataSource) ||
      getContextSrv()?.hasPermissionInMetadata(AccessControlAction.DataSourcesWrite, dataSource)
    )
  );
};

export const canReadPrivateNetworks = (): boolean => {
  return !!(
    getContextSrv()?.hasRole?.('Viewer') ||
    getContextSrv()?.hasRole?.('Editor') ||
    getContextSrv()?.hasRole?.('Admin') ||
    getContextSrv()?.hasPermission(AccessControlAction.PrivateNetworksRead)
  );
};
